* {
  box-sizing: border-box;
  margin: 0;
  padding: 0
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth
}

body {
  background-color: #fff;
  font-family: Poppins,sans-serif;
  position: relative
}

.container {
  margin: 0 auto;
  max-width: 107rem;
  padding: 0 4rem
}

* {
  scrollbar-color: #8e8e8e #fff;
  scrollbar-width: thin
}

::-webkit-scrollbar {
  width: 8px
}

::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 20px
}

::-webkit-scrollbar-track:active,::-webkit-scrollbar-track:hover {
  background-color: #fff
}

::-webkit-scrollbar-thumb {
  background-color: #8e8e8e;
  border-radius: 20px
}

::-webkit-scrollbar-thumb:hover {
  background-color: #878787
}

::-webkit-scrollbar-thumb:active {
  background-color: #8e8e8e
}

.mobile-menu {
  cursor: pointer;
  display: none;
  font-size: 2.3rem
}

.mobile-menu:hover {
  color: #147efb;
  transition: all .3s
}

@media (max-width: 900px) {
  .mobile-menu {
      display:flex
  }
}

.closed-menu {
  left: -100%!important
}

.open-menu {
  left: 0!important
}

.mobile-nav {
  align-items: center;
  background-color: #fff;
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  text-align: center;
  top: 0;
  transition: all .3s ease-in-out;
  width: 100%;
  z-index: 300
}

.mobile-nav span {
  cursor: pointer;
  font-size: 3.3rem;
  position: absolute;
  right: 4rem;
  top: 2rem
}

.mobile-nav span:hover {
  color: #147efb;
  transition: all .3s
}

.mobile-nav ul {
  display: flex;
  flex-direction: column;
  font-size: 2.3rem;
  gap: 4rem;
  list-style: none
}

.mobile-nav ul a {
  color: #000;
  font-weight: 500;
  text-decoration: none
}

.mobile-nav ul a:hover {
  color: #147efb;
  transition: all .3s
}

nav {
  align-items: center;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0,0,0,.09);
  display: flex;
  font-size: 1.7rem;
  height: auto;
  justify-content: space-between;
  left: 0;
  padding: 25px 40px 25px 50px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 200
}

@media (max-width: 400px) {
  nav {
      padding:25px 30px
  }
}

nav ul {
  display: flex;
  gap: 2rem;
  list-style: none
}

nav ul a {
  color: #2d2e32;
  font-weight: 600;
  text-decoration: none;
  transition: all .23s
}

@media (max-width: 900px) {
  nav ul a {
      display:none
  }
}

nav ul a:hover {
  color: #147efb
}

.logo {
  color: #2d2e32;
  cursor: pointer
}

.hero {
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: relative;
  width: 100%
}

@media (max-width: 900px) {
  .hero {
      height:auto;
      padding: 11rem 0
  }
}

.hero-main {
  align-items: center;
  display: flex;
  gap: 10rem;
  justify-content: center;
  position: relative
}

@media (max-width: 900px) {
  .hero-main {
      flex-direction:column-reverse;
      gap: 3rem;
      height: auto;
      text-align: center
  }
}

.content {
  align-items: center;
  display: flex;
  gap: 10rem;
  height: 65rem;
  justify-content: center;
  position: relative
}

@media (max-width: 900px) {
  .content {
      flex-direction:column;
      gap: 3rem;
      height: auto;
      text-align: center
  }
}

@media (min-width: 1600px) {
  .content {
      margin-top:6rem
  }
}

@media (min-width: 1900px) {
  .content {
      margin-top:10rem
  }
}

.hero-text {
  display: flex;
  flex-direction: column;
  max-width: 50rem;
  position: relative
}

.hero-text h1 {
  color: #2d2e32;
  font-size: 5.5rem;
  line-height: 1.2;
  margin-bottom: 2rem;
  margin-top: 2rem
}

@media (max-width: 500px) {
  .hero-text h1 {
      font-size:4rem
  }
}

.hero-text p {
  color: #767676;
  font-family: Mulish,sans-serif;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.6
}

@media (max-width: 900px) {
  .hero-text p {
      margin-bottom:5rem
  }
}

.hero-text span {
  cursor: pointer;
  display: flex;
  gap: 1.3rem;
  margin: 2.5rem 0
}

.hero-text span a {
  color: #2d2e32;
  font-size: 3rem
}

@media (max-width: 900px) {
  .hero-text span {
      gap:1.6rem;
      justify-content: center;
      margin-bottom: 4rem;
      margin-top: -2rem
  }
}

.hero-text span i {
  transition: all .2s
}

.hero-text span i:hover {
  color: #147efb
}

.hero-text img {
  position: absolute;
  right: 13rem;
  top: 8.7rem;
  width: 6rem
}

@media (max-width: 900px) {
  .hero-text img {
      right:3rem
  }
}

@media (max-width: 500px) {
  .hero-text img {
      right:4rem;
      top: 6.4rem;
      width: 4.5rem
  }
}

@media (max-width: 400px) {
  .hero-text img {
      right:3rem
  }
}

@media (max-width: 380px) {
  .hero-text img {
      right:3rem
  }
}

@media (max-width: 375px) {
  .hero-text img {
      right:1.7rem
  }
}

.hero-img {
  -webkit-animation: morph 8s ease-in-out infinite;
  animation: morph 8s ease-in-out infinite;
  background-image: url(assets/About_me_img.png);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border: 3px solid #2d2e32;
  border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  height: 35rem;
  position: relative;
  transition: all 1s ease-in-out;
  width: 35rem
}

@media (max-width: 500px) {
  .hero-img {
      height:28rem;
      width: 28rem
  }
}

@-webkit-keyframes morph {
  0% {
      border-radius: 60% 40% 30% 70%/60% 30% 70% 40%
  }

  50% {
      border-radius: 30% 60% 70% 40%/50% 60% 30% 60%
  }

  to {
      border-radius: 60% 40% 30% 70%/60% 30% 70% 40%
  }
}

@keyframes morph {
  0% {
      border-radius: 60% 40% 30% 70%/60% 30% 70% 40%
  }

  50% {
      border-radius: 30% 60% 70% 40%/50% 60% 30% 60%
  }

  to {
      border-radius: 60% 40% 30% 70%/60% 30% 70% 40%
  }
}

.skills {
  align-items: center;
  bottom: 0;
  color: #767676;
  display: flex;
  font-size: 1.7rem;
  left: 0;
  position: absolute
}

@media (max-width: 900px) {
  .skills {
      flex-direction:column;
      position: static
  }
}

.skills p {
  border-right: 2px solid rgba(45,46,50,.5);
  color: #2d2e32;
  font-family: Mulish,sans-serif;
  font-weight: 600;
  margin-right: 7rem;
  padding-right: 2rem
}

@media (max-width: 900px) {
  .skills p {
      border-bottom:2px solid rgba(45,46,50,.5);
      border-right: none;
      margin-bottom: 3rem;
      margin-right: 0;
      padding-bottom: 1rem;
      padding-right: 0
  }
}

.skills ul {
  display: flex;
  flex-wrap: wrap;
  gap: 2.5rem;
  list-style: none
}

@media (max-width: 900px) {
  .skills ul {
      justify-content:center
  }
}

.skills ul li {
  align-items: center;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0,0,0,.09);
  cursor: pointer;
  display: flex;
  height: 6.5rem;
  justify-content: center;
  width: 6.5rem
}

.skills ul li img {
  height: auto;
  width: 3.4rem
}

.experience {
  background-color: #fff;
  padding: 15rem 0; 
}

.experience-content {
  align-items: center;
  text-align: center;
}

.experience-content h1 {
  color: #147efb;
  font-size: 3rem;
  font-weight: 300;
}

.experience-content h2 {
  margin: 0 auto 1em auto;
  font-size: 6rem;
  color: #2d2e32;
}

.verticalTimeline {
  text-align: left;
}

.experience-content h3 {
  padding-top: 0.25em;
  font-size: 2rem;
}

.experience-content h5 {
  color: #2d2e32;
  font-family: Mulish,sans-serif;
  font-weight: 600;
  margin-right: 7rem;
  padding-right: 2rem;
  font-size: 2rem;
}


.vertical-timeline-element-content {
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25),
    0 0.4em 1.25em 0 rgba(0, 0, 0, 0.15) !important;
  padding: 2em 3em !important;
  background-color: #4A4E69;
  color: black;
}

@media only screen and (max-width: 1700px) {
  .vertical-timeline-element-date {
    display: block !important;
    float: none !important;
    color: rgb(44, 44, 44);
  }
}

.vertical-timeline-element-date {
  font-size: 20px !important;
}

.vertical-timeline-element-description {
  color: #767676;
  font-family: Mulish,sans-serif;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.6;
}

.projects {
  background-color: #f9f9f9;
  height: auto;
  padding: 15rem 0; 
}

.project-content {
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.project-content h1 {
  color: #147efb;
  font-size: 3rem;
}

.project-content h2 {
  margin: 0 auto 1em auto;
  font-size: 6rem;
  color: #2d2e32;
}

.project-list {
  height: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  place-items: center;
}


/* MENU ITEM STYLING */

.project-item {
  border-radius: 15px;
  width: 300px;
  height: 300px;
  margin: 40px;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
  text-align: center;
  background-color: #8e8e8e;
  transition: transform .5s; /* Animation */
}

.project-item h1 {
  margin-top: 1em;
  color: #fff;
}

.project-item:hover {
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.5);
  transition: 0.4s ease-in;
  cursor: pointer;
  transform: scale(1.1);
}

.project-item .bgImage {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  width: 100%;
  height: 200px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.project-item h1 {
  font-size: 25px;
}

.project-link {
  text-decoration: none;
}

@media only screen and (max-width: 1300px) {
  .project-list {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 800px) {
  .project-list {
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 800px) {
  .project-list {
    width: 100%;
  }
  .project-item {
    width: 300px;
    height: 300px;
  }
}

.contact {
  background-color: #fff;
  padding: 10rem 0;
}

.contact-content {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.contact-content h1 {
  font-size: 4rem;
}

.contact-info div {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

.contact-info img{
  width: 3rem;
}

.contact-row {
  display: flex;
  align-items: center;
  justify-content: left;
}

.contact-row div {
  padding: 2rem 0 0 2rem;
}

.josephineContainer {
  display: flex;
  flex-wrap: wrap;
  background-color: pink;
  height: 100vh;
  width: 100%;
}

.photoContainer {
  height: 50%;
  width: 25%;
  border: 1px solid black;
}

.photoContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.envelope {
  width: 75%;
  font-size: 24pt;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: antiquewhite;
}